@charset "UTF-8";
/*
*   Generic mixins
*
 */
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  justify-content: space-between;
  background-color: #29323e;
  color: #F7F9FB;
  position: relative;
  z-index: 2;
  lineHeight: 1.2;
  min-height: 54px; }

.lang {
  flex: 0 0 auto;
  width: 80px;
  align-self: stretch;
  background-color: #1C2228;
  position: relative; }
  .lang > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.scroller {
  width: 100%;
  flex: 0 1 auto;
  position: relative;
  z-index: 1; }
  .scroller > * {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 20px;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }
    .scroller > * > * {
      flex: 0 0 auto;
      padding-right: 5%;
      white-space: nowrap; }
      .scroller > * > *:last-child {
        padding-right: 40px; }

.palette {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
  background-color: #1C2228;
  padding: 12px 20px;
  position: relative;
  z-index: 2; }
  .palette::before {
    content: "";
    display: block;
    width: 30px;
    height: 100%;
    background: linear-gradient(280deg, #29323d 22%, rgba(41, 50, 61, 0) 100%);
    position: absolute;
    right: 100%;
    top: 0; }

.palettecolor {
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  margin: 3px;
  flex: 0 0 auto;
  border: 1px solid rgba(247, 249, 251, 0.2); }
