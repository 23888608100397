@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  flex-direction: column; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.label {
  font-size: 12px;
  line-height: 1.2;
  padding: 0 0 5px;
  font-weight: 500; }

.inputwrap {
  position: relative; }

.input {
  color: #000000;
  font-size: 14px;
  line-height: 1.2; }
  .input .flag {
    width: 8px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    margin: -8px 0 0;
    pointer-events: none;
    z-index: 2; }
    :global(.is-active) .input .flag {
      top: 10px; }
    .input .flag svg {
      display: block;
      width: 100%;
      height: 100%; }
  .input .flag + .select :global(.tags-dropdown__control) {
    padding-left: 4px; }

.instructions {
  font-size: 12px;
  line-height: 1.3;
  font-effect: 500;
  padding-top: 5px;
  opacity: 0.65; }
  .instructions em {
    font-weight: 600; }

.suggestions {
  display: flex;
  padding: 0;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 5px;
  user-select: none; }
  .suggestions:hover, .suggestions:active {
    text-decoration: underline; }
  .suggestions span {
    display: inline-block;
    opacity: 0.75;
    flex: 0 0 auto; }
  .suggestions svg {
    width: 14px;
    height: 14px;
    flex: 0 0 auto;
    margin-right: 6px; }
