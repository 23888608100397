@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
/*
*   Generic mixins
*
 */
.root {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #465567;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column; }

.main {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.editor {
  height: 100%;
  background-color: #333F4D; }

.contentwrap {
  color: #F7F9FB;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch; }
  .contentwrap .content {
    padding: 30px 40px 40px; }

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 60px;
  flex: 0 0 auto;
  margin-top: auto;
  background-color: #F7F9FB;
  position: relative;
  padding: 10px 20px;
  z-index: 2;
  border-top: 1px solid rgba(53, 63, 76, 0.1); }

.status {
  flex: 0 0 auto; }

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  flex: 2; }
  .actions > * {
    flex: 0 0 auto;
    margin-left: 12px; }
    .actions > *:first-child {
      margin: 0; }

.rating {
  display: flex;
  align-items: center;
  flex: 2; }
  .rating > * {
    margin-right: 8px; }

.nav {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  flex: 0 0 auto;
  margin: 0 auto; }
  .nav .delimiter {
    width: 1px;
    background-color: currentColor;
    opacity: 0.5;
    flex: 0 0 auto;
    align-self: stretch; }
  .nav > * {
    margin-right: 15px;
    flex: 0 0 auto; }
    .nav > *:last-child {
      margin-right: 0; }
  .nav a:hover, .nav a:active, .nav button:hover, .nav button:active {
    text-decoration: underline; }

.nextprev {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500; }
  .nextprev p {
    opacity: 0.75; }
  .nextprev .prevbtn,
  .nextprev .nextbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 19px;
    height: 19px;
    position: relative;
    background-color: currentColor;
    opacity: 0.5; }
    .nextprev .prevbtn::before,
    .nextprev .nextbtn::before {
      content: "";
      display: block;
      position: absolute;
      width: 44px;
      height: 44px;
      left: 50%;
      top: 50%;
      margin: -22px 0 0 -22px; }
    .nextprev .prevbtn svg,
    .nextprev .nextbtn svg {
      width: 8px;
      height: 5px;
      stroke-width: 3px;
      stroke: #F7F9FB; }
    .nextprev .prevbtn:hover, .nextprev .prevbtn:active,
    .nextprev .nextbtn:hover,
    .nextprev .nextbtn:active {
      opacity: 1; }
  .nextprev .prevbtn {
    margin-right: 10px; }
    .nextprev .prevbtn svg {
      transform: rotate(90deg); }
  .nextprev .nextbtn {
    margin-left: 10px; }
    .nextprev .nextbtn svg {
      transform: rotate(-90deg); }

.modebtn a, .modebtn button {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer; }
  .modebtn a::before, .modebtn button::before {
    content: "";
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    left: 50%;
    top: 50%;
    margin: -22px 0 0 -22px; }
  .modebtn a svg, .modebtn button svg {
    width: 100%;
    height: 100%;
    opacity: 0.5; }
  .modebtn a:hover svg, .modebtn a:active svg, .modebtn button:hover svg, .modebtn button:active svg {
    opacity: 1; }
