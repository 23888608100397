@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.menu {
  position: absolute;
  width: auto;
  min-width: 100%;
  max-width: 250px;
  z-index: 1;
  font-size: 14px;
  line-height: 1.2;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: -2px 0px 5px 0px rgba(51, 63, 77, 0.2);
  padding: 6px 0;
  user-select: none; }
  .menu:global(.arrowup)::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 6px;
    margin-left: -6px; }
  .menu:global(.arrowdown)::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 6px;
    margin-left: -6px; }
  .menu:global(.arrowright)::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #ffffff;
    border-width: 6px;
    margin-top: -6px; }
  .menu:global(.arrowleft)::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 6px;
    margin-top: -6px; }

.options button, .options a, .options label {
  display: block;
  width: 100%;
  padding: 12px 30px 12px 20px;
  white-space: nowrap;
  color: #000000;
  font-size: 14px;
  line-height: 1.2; }
  .options button:hover, .options a:hover, .options label:hover {
    background-color: #C8CACD; }

.options hr {
  border: none;
  border-bottom: 1px solid #E6E5E6;
  margin: 6px 0; }
