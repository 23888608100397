@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
  padding: 80px; }

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 960px;
  margin: auto; }

.status {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 10px;
  padding-left: 10px; }

.bar {
  width: 100%;
  height: 20px;
  border-radius: 30px;
  border: 3px solid #353F4C;
  position: relative;
  overflow: hidden;
  background-color: #ffffff; }

.progress {
  width: 100%;
  height: 100%;
  background-color: #353F4C;
  transform-origin: left center; }

.stripes {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 15px, #ccc 15px, #ccc 30px);
  background-size: 200% 100%;
  animation: barberpole 10s linear infinite;
  opacity: 0.5; }

@keyframes barberpole {
  100% {
    background-position: 100% 100%; } }
