.root {

    background-color: #F0F4F7;
    color: #333F4D;
    border: 1px solid #BDC0C2;

    &:active {
        background-color: darken(#F0F4F7, 5%);
    }
}

.submit {

    background-color: #d75946;
    color: #ffffff;
    border: none;

    &:active {
        background-color: darken(#d75946, 5%);
    }

}
