.root {

    label {
        font-size: 10px;
        line-height: 1.2;
    }

    .input {
        margin-top: -2px;
    }
}
