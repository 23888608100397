$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 600;

/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorCatSkillWhite: #F7F9FB;
$colorOxfordBlue: #333F4D;
$colorRedDamask: #D75946;
$colorFiord: #465567;
$colorGray: #8F8F8F;
$colorEbonyClay: #2A313D;
$colorShark: #1C2228;
$colorCasablanca: #FCB61A;

/*
*   Breakpoints
*
 */

// The different breakpoints are px values, but the unit has to be omitted
$small: 440;
$smallPlus: 600;
$medium: 750;
$mediumPlus: 980;
$large: 1280;
$largePlus: 1440;
$xlarge: 2000;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    xl: $xlarge,
);

// Which unit should the breakpoints use? em, px or rem (em is default)
$breakpointsUnit: px; // PX, EM or REM Media Queries? -> https://zellwk.com/blog/media-query-units/
