.u {
    &-sr-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.t {
    &-ellipsis {
        border: 1px solid pink;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
