@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.restrictions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #FCB61A;
  padding: 5px 8px 7px; }
  .restrictions p {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding-left: 8px; }
  .restrictions svg {
    display: block;
    width: 13px;
    height: 13px;
    fill: currentColor;
    flex: 0 0 auto; }

.meta {
  display: flex;
  align-items: center;
  padding-top: 5px;
  user-select: none; }
  .meta .favbutton {
    display: block;
    flex: 0 0 auto;
    margin-right: 8px; }
  .meta .infobtn {
    display: block;
    width: 12px;
    height: 12px; }
    .meta .infobtn svg {
      display: block;
      width: 100%;
      height: 100%; }
      .meta .infobtn svg *[fill="#EFC30C"] {
        fill: currentColor !important; }
  .meta .alert {
    display: flex;
    flex: 0 0 auto;
    margin-left: auto;
    position: relative;
    align-items: center; }
    .meta .alert > * {
      display: block;
      flex: 0 0 auto;
      margin-right: 5px; }
      .meta .alert > *:last-child {
        margin: 0; }
  .meta .name {
    font-size: 10px;
    line-height: 1.2;
    margin-top: -2px;
    font-weight: 500;
    position: relative;
    width: 100%;
    flex: 1 1 auto; }
    .meta .name > * {
      display: block;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%); }
      .meta .name > * > * {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
