@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  font-size: 14px;
  user-select: none; }

.container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  position: relative; }
  .container:hover {
    text-decoration: underline; }

.box {
  display: flex;
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  position: relative;
  border: 1px solid rgba(45, 55, 68, 0.4);
  border-radius: 2px;
  box-shadow: inset 2px 2px 4px -1px rgba(51, 63, 77, 0.1); }
  :global(.is-radio) .box {
    border-radius: 100%; }
  .box svg {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -6px 0 0 -6px; }

.dot {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -4px 0 0 -4px;
  background-color: #444F5B;
  border-radius: 100%; }

.label {
  display: inline-block;
  flex: 0 1 auto;
  padding-left: 8px;
  font-weight: 500; }
