@charset "UTF-8";
/*
*   Generic mixins
*
 */
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  background-color: #465567;
  color: #ffffff; }
  .root > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 40px;
    width: 100%;
    max-width: 420px; }
  .root .logo {
    margin-bottom: 10px;
    max-width: 180px; }
    .root .logo path {
      fill: white; }
  .root .submit {
    padding-top: 5px; }
    .root .submit button {
      min-width: 90px; }
  .root form {
    width: 100%;
    padding: 20px 25px 30px;
    border-radius: 4px; }
    .root form > * {
      margin-bottom: 20px; }
      .root form > *:last-child {
        margin: 0; }
  .root .error {
    font-size: 14px;
    line-height: 1.2;
    color: #ffffff; }
