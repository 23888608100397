@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.2; }
  .root label {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 0 5px;
    font-weight: 500; }

.header {
  display: flex; }

.locale {
  flex: 0 0 auto;
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 1.2;
  display: inline-block;
  background-color: rgba(247, 249, 251, 0.85);
  border-radius: 3px;
  padding: 0 4px 2px;
  color: #000000; }
  * + .locale {
    margin-left: 5px; }

.input {
  position: relative;
  color: #000000;
  background-color: #F7F9FB;
  border-radius: 3px; }
  .input input, .input textarea {
    color: #000000;
    width: 100%;
    font-size: 12px;
    position: relative;
    z-index: 1; }
    .input input:focus, .input textarea:focus {
      background-color: rgba(70, 85, 103, 0.05); }
  .input input {
    height: 38px;
    padding: 0 12px; }
  .input textarea {
    padding: 11px 12px 10px; }

.postfix {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 12px;
  height: 100%;
  z-index: 2;
  font-size: 12px;
  color: rgba(70, 85, 103, 0.75);
  user-select: none; }

.error {
  color: #D75946;
  padding-top: 4px;
  font-weight: 500; }

.instructions {
  font-size: 12px;
  line-height: 1.3;
  font-effect: 500;
  padding-top: 5px;
  opacity: 0.65; }
  .instructions em {
    font-weight: 600; }
