/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/
::-webkit-scrollbar {
    display: none;
}

* {
    outline: none;
}

svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
}

[hidden] {
    display: none;
}

blockquote {
    margin: 0;
}

cite {
    font-style: normal;
}

button {
    display: inline-block;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: currentColor;
    border-radius: 0;
    appearance: none;
    text-align: left;
}

table {
    border-collapse: collapse;
}

th, td {
    text-align: left;
    vertical-align: top;
    font-weight: normal;
}

input, textarea {
    border: none;
    color: currentColor;
    background-color: transparent;
}

a, button {
    svg {
        pointer-events: none;
    }
}
