@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  font-size: 14px;
  line-height: 1.2;
  color: #000000; }
  .root label {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 0 4px; }
