.lazyload,
.lazyloading {
    opacity: 0.0001;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.5s;
}

.no-js {
    img.lazyload {
        display: none;
    }
}
