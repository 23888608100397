@charset "UTF-8";
/*
*   Generic mixins
*
 */
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }

.files {
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%; }
  .files > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }

.thumbs {
  position: relative;
  z-index: 1;
  min-height: 100%; }

.actions {
  width: 100%;
  flex: 0 0 auto;
  margin-top: auto;
  position: relative;
  z-index: 100; }
