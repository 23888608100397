@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 18px 8px;
  flex: 0 0 auto;
  position: relative;
  z-index: 2; }

.filters {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  position: relative; }

.search {
  flex: 1 1 auto;
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.2; }

.categories {
  flex: 0 0 auto;
  padding: 0 8px;
  min-width: 200px; }

.settings {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 4px 0;
  flex-wrap: wrap; }
  .settings .primary {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: 0 8px; }
    .settings .primary > * {
      position: relative;
      padding: 0 15px; }
      .settings .primary > *::before {
        content: "";
        display: block;
        width: 1px;
        height: 70%;
        position: absolute;
        top: 15%;
        left: 0;
        opacity: 0.15;
        background-color: currentColor; }
      .settings .primary > *:first-child {
        padding-left: 0; }
        .settings .primary > *:first-child::before {
          content: none; }
    .settings .primary .extrafilters {
      display: flex;
      align-items: center; }
      .settings .primary .extrafilters > *:not(:last-child) {
        margin-right: 15px; }
    .settings .primary .progress {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1.2;
      flex: 1 1 auto;
      font-weight: 500;
      padding-right: 0; }
  .settings .scaler {
    flex: 0 0 auto;
    margin-left: auto;
    padding-left: 20px; }
