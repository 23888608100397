@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.dialog {
  background-color: #ffffff;
  border-radius: 3px 3px 3px 3px;
  padding: 24px 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  min-width: 420px;
  max-width: 520px;
  margin: 20px; }
  .dialog em {
    font-weight: bold;
    font-style: normal; }

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5; }

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5; }

.title + .text {
  padding-top: 12px; }

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 0; }

.confirm {
  display: flex;
  margin-left: auto;
  order: 1; }
  .confirm > * {
    margin: 0 0 0 15px;
    min-width: 80px; }
    .confirm > *:first-child {
      margin: 0; }

.dangerous {
  margin-right: 50px;
  font-size: 14px;
  line-height: 1.2; }
  .dangerous, .dangerous * {
    font-weight: 500; }
