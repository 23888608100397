@charset "UTF-8";
/*
*   Generic mixins
*
 */
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 64px; }
  .root > * {
    padding: 0 16px; }

.actions {
  flex: 0 0 auto; }

.files {
  width: 100%;
  flex: 0 1 auto;
  position: relative; }
  .files::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -25px;
    width: 30px;
    height: 50px;
    background: linear-gradient(270deg, #f6f8fa 20%, rgba(246, 248, 250, 0) 90%);
    pointer-events: none;
    z-index: 2; }
  .files > * {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    max-width: 100%;
    transform: translate(0, -50%);
    z-index: 1;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }
    .files > * > * {
      display: flex;
      align-items: center;
      padding-right: 40px; }

.file {
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  background-color: gainsboro;
  position: relative; }
  .file + .file {
    margin-left: 4px; }
  .file button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .file button::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.75);
      opacity: 0;
      transition: opacity 0.3s; }
    .file button svg {
      width: 14px;
      height: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -7px 0 0 -7px;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s; }
    .file button:hover svg, .file button:hover::before {
      opacity: 1; }

.thumbs {
  display: flex;
  flex: 1 1 auto; }
  .thumbs > * {
    display: flex;
    align-items: center; }

.fileactions {
  flex: 0 0 auto; }
  .fileactions button {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.2;
    white-space: nowrap;
    padding: 0; }
    .fileactions button:hover {
      text-decoration: underline; }
    .fileactions button span {
      display: inline-block;
      padding: 16px 0 16px 16px; }
    .fileactions button svg {
      width: 14px;
      height: 14px;
      margin-left: 6px; }
