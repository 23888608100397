@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
/*
*   Generic mixins
*
 */
.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #333F4D;
  color: #F7F9FB;
  position: relative;
  z-index: 2; }

.primary {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto; }

.secondary {
  margin-top: auto;
  width: 100%;
  flex: 0 0 auto; }

.button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  background-color: #465567;
  margin-bottom: 1px;
  font-weight: 500;
  flex: 0 0 auto;
  cursor: pointer;
  text-align: center;
  position: relative; }
  .button:last-child {
    margin: 0; }
  .button:not([disabled]):active, .button:not([disabled]):hover {
    background-color: #506276; }
  .button span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 18px 20px; }
  .button img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: none; }
