@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex; }
  .root legend {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 0 8px;
    font-weight: 500; }

.options {
  margin-bottom: -15px; }
  .options > * {
    margin-bottom: 15px; }
  :global(.is-vertical) .options {
    flex-direction: column; }
  :global(.is-horizontal) .options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    :global(.is-horizontal) .options > * {
      margin-right: 15px; }
      :global(.is-horizontal) .options > *:last-child {
        margin-right: 0; }
  :global(.is-columns) .options {
    display: block;
    column-width: 120px;
    column-gap: 20px; }
    :global(.is-columns) .options > * {
      display: inline-block;
      width: 100%; }
