@charset "UTF-8";
/*
*   Colors – named by http://chir.ag/projects/name-that-color
 */
/*
*   Breakpoints
*
 */
.root {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.2; }
  .root legend {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 0 5px;
    font-weight: 500; }

.header {
  display: flex; }

.inputwrap {
  background-color: #F7F9FB;
  color: #000000;
  position: relative; }
  .inputwrap:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .inputwrap:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
  .inputwrap > * {
    border-bottom: 1px solid #D7DDE2; }
  .inputwrap:last-child > * {
    border-bottom: none; }

.input {
  position: relative;
  width: 100%;
  display: flex;
  align-content: center; }
  .input .flag {
    width: 8px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    margin: -8px 0 0;
    pointer-events: none;
    z-index: 2; }
    .input .flag svg {
      display: block;
      width: 100%;
      height: 100%; }
  .input input, .input textarea {
    display: block;
    color: #000000;
    background-color: transparent;
    width: 100%;
    font-size: 12px;
    position: relative;
    z-index: 1; }
    .input input:focus, .input textarea:focus {
      background-color: rgba(70, 85, 103, 0.05); }
  .input input {
    height: 38px;
    padding: 0 40px 0 24px; }
  .input textarea {
    min-height: 38px;
    padding: 11px 40px 11px 24px;
    line-height: 1.4;
    resize: none; }
  .input .translate {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer; }
    .input .translate[disabled] {
      opacity: 0.5;
      cursor: default; }
    .input .translate svg {
      display: block;
      width: 100%;
      height: 100%; }
  .input button + div button {
    font-size: 12px !important;
    padding: 8px 14px 6px 14px !important; }

.postfix {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  height: 100%;
  z-index: 2;
  line-height: 1;
  font-size: 10px;
  font-weight: 500;
  color: rgba(70, 85, 103, 0.75);
  user-select: none; }

.error {
  color: #D75946;
  padding-top: 4px;
  font-weight: 500; }

.instructions {
  font-size: 12px;
  line-height: 1.3;
  font-effect: 500;
  padding-top: 5px;
  opacity: 0.65; }
  .instructions em {
    font-weight: 600; }
