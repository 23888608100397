.root {
    display: flex;
}

.main {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    z-index: 1;
    height: 100%;
}

.sidebar {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    box-shadow: -2px 0px 5px 0px rgba(51, 63, 77, 0.37);
}
